import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { LoadingAnim } from '../components/Loading';
import { getAcp } from '../utils/api';
import { NewsPost } from './News';

const LatestCardRoot = () => <App />;

const App = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [newsPost, setNewsPost] = useState<NewsPost>();
  const [error, setError] = React.useState<string | null>(null);
  const headerRef = React.useRef<HTMLHeadingElement>(null);

  const loadNewsPosts = async () => {
    setIsLoading(true);
    const news: NewsPost[] = await getAcp('news');

    // map all news posts to a new array and set the time to a readable format
    // then set the first news post to the state
    setNewsPost(
      news.map(newsPost => {
        newsPost.time = new Date(newsPost.time).toDateString();
        return newsPost;
      })[0],
    );

    setIsLoading(false);
    setInitialLoad(true);
  };

  const handleClickNewsPost = (id: number) => {
    window.location.href = `/?news=${id}`;
  };

  React.useEffect(() => {
    loadNewsPosts();
  }, []);

  return (
    <>
      <h1 ref={headerRef}>
        <span className="white-header">Latest updates</span>
        <span className="dot">.</span>
      </h1>
      {isLoading && <LoadingAnim />}
      {!isLoading && !newsPost && !error && initialLoad && (
        <Alert>No news.</Alert>
      )}
      {newsPost && !error && (
        <div className="card text-white bg-dark" key={newsPost.id}>
          <img
            src={newsPost.image}
            className="card-img-top\"
            alt={newsPost.title}
          />
          <div className="card-no-border card-body">
            <h5 className="card-title">{newsPost.title}</h5>
            <hr />
            <div>
              <a
                onClick={() => handleClickNewsPost(newsPost.id)}
                className="btn btn-block btn-primary">
                Read More
              </a>
              <span className="badge badge-xs bg-grey float-end">
                {newsPost.time}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LatestCardRoot;
